<template>
  <div class="">
    <MessageResend ref="resend">
      <p class="">
        <span class="mb-14 mt-18 block text-left font-bold">
          About to resend {{ resendData.number }}
          {{ resendData.column }} messages to {{ resendData.topic }}<br />
          Are you sure?
        </span>
      </p>
    </MessageResend>

    <div
      v-if="isReady"
      ref="gridBox"
      class="gridlist grid-Scroll grid-ScrollList"
    >
      <AgGridVue
        :style="{ width, height }"
        class="ag-theme-fresh"
        :row-height="rowHeight"
        :default-col-def="defaultColDef"
        :column-defs="columnDefs"
        :pagination="true"
        :pagination-page-size="paginationPageSize"
        :row-data="topics"
        :modules="modules"
        :master-detail="true"
        :detail-cell-renderer-params="detailCellRendererParams"
        :detail-row-height="detailRowHeight"
        :row-class-rules="rowClassRules"
        @grid-ready="onGridReady"
      >
      </AgGridVue>
    </div>
    <Spinner v-else />
  </div>
</template>
<script>
import 'ag-grid-community/dist/styles/ag-theme-fresh.css'
import { AgGridVue } from '@ag-grid-community/vue'
import { SetFilterModule } from '@ag-grid-enterprise/set-filter'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { MenuModule } from '@ag-grid-enterprise/menu'
import { RichSelectModule } from '@ag-grid-enterprise/rich-select'
import { AllModules } from '@ag-grid-enterprise/all-modules'
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail'
import MessageResend from '../../components/Modal/MessageResend.vue'
const treeOpen = '/images/tree-open.svg'

const treeClosed = '/images/tree-closed.svg'

export default {
  components: {
    AgGridVue,
    MessageResend,
  },
  inject: ['store'],
  props: {
    response: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      topics: [],
      resendData: {},
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      width: '100%',
      height: '80vh',
      // rowSelection: 'multiple',
      rowClassRules: null,
      makeMasterCellRenderer: null,
      openDetail: null,
      modules: [
        SetFilterModule,
        ClientSideRowModelModule,
        MenuModule,
        ColumnsToolPanelModule,
        RichSelectModule,
        AllModules,
        MasterDetailModule,
      ],
      detailCellRendererParams: null,
      detailRowHeight: null,
      rowHeight: 50,
      number: null,
      blob: '',
      onCellValueChanged: null,
    }
  },
  computed: {
    isReady() {
      return this.store.assort.topics
    },
    isAppName() {
      return this.store.user.appName
    },
  },
  watch: {
    isReady(newVal, oldVal) {
      if (newVal) {
        this.topics = this.store.assort.topics
      }
    },
    isAppName(newVal, oldVal) {
      if (newVal) {
        this.topics = this.store.assort.topics
      }
    },
    response(newVal, oldVal) {
      if (newVal) {
        this.topics = this.response
      }
    },
  },

  beforeMount() {
    // const self = this

    this.gridOptions = {
      // floatingFiltersHeight: 60,
      // headerHeight: 60,1
    }
    this.defaultColDef = {
      sortable: true,
      resizable: true,
      filter: 'agTextColumnFilter',
      cellStyle: {
        'background-color': '#E5E5E5',
        'border-bottom': '2px solid #D8D8D8',
        'padding-top': '10px;',
        transform: 'translateY(0px)',
      },
    }
    this.paginationPageSize = 10
    this.paginationNumberFormatter = params => {
      return '[' + params.value.toLocaleString() + ']'
    }
    this.topics = this.store.assort.topics

    this.columnDefs = [
      {
        headerName: 'Topic Name',
        field: 'name',
        width: 300,
      },
      {
        headerName: 'Added',
        width: 100,
        field: 'added',
        cellRenderer: params => {
          return this.makeMasterCellRenderer(params, 'added')
        },
      },
      {
        cellRenderer: this.replayButton.bind(this, 'Added'),
        width: 150,
      },
      {
        headerName: 'Removed',
        field: 'removed',
        width: 100,
        cellRenderer: params => {
          return this.makeMasterCellRenderer(params, 'removed')
        },
      },
      {
        cellRenderer: this.replayButton.bind(this, 'Removed'),
        width: 160,
      },
      {
        headerName: 'Changed',
        width: 100,
        field: 'changed',
        cellRenderer: params => {
          return this.makeMasterCellRenderer(params, 'changed')
        },
      },
      {
        cellRenderer: this.replayButton.bind(this, 'Changed'),
        width: 160,
      },
      {
        headerName: 'Unsent',
        width: 100,
        field: 'unsent',
        cellRenderer: params => {
          return this.makeMasterCellRenderer(params, 'unsent')
        },
      },
      {
        cellRenderer: this.replayButton.bind(this, 'Unsent'),
        width: 150,
      },
    ]
  },
  async mounted() {
    this.topics = await this.store.assort.fetchCompareTopics()
  },
  created() {
    this.detailRowHeight = 500
    this.makeMasterCellRenderer = (params, col) => {
      let isExpanded = params.node.expanded
      let container = document.createElement('div')
      let chevron = document.createElement('img')
      let span = document.createElement('span')
      let openCol = null

      chevron.classList.add(`row-${params.node.data.id}`)
      chevron.classList.add('pointer-class')
      container.classList.add('master-container')

      if (params.context) {
        openCol = params.context.chevKeyMap[chevron.className]
      }
      let chevronState = isExpanded && col === openCol ? treeOpen : treeClosed
      chevron.setAttribute('src', chevronState)

      span.innerText = params.value
      container.appendChild(chevron)

      chevron.addEventListener('click', event => {
        let chevron = event.target
        this.openDetail(params, col, chevron)
      })

      container.appendChild(span)
      return container
    }
    this.openDetail = async (params, column, cellRenderer) => {
      if (
        this.gridOptions.context &&
        this.gridOptions.context.chevKeyMap[cellRenderer.className] ===
          column &&
        params.node.expanded
      ) {
        params.node.setExpanded(false)
        cellRenderer.setAttribute('src', treeClosed)
        return
      }
      this.gridOptions.context = {
        ...this.gridOptions.context,
        selectedDetail: column,
      }
      let className = ''
      cellRenderer.classList.forEach(cssClass => {
        if (cssClass.indexOf('row') !== -1) {
          className = cssClass
        }
      })
      let nodeRenderers = document.querySelectorAll(`.${className}`)
      nodeRenderers.forEach(renderer => {
        renderer.setAttribute('src', treeClosed)
      })
      params.node.setExpanded(true)
      if (this.gridOptions.context.chevKeyMap) {
        this.gridOptions.context.chevKeyMap[cellRenderer.className] = column
      } else {
        this.gridOptions.context.chevKeyMap = {}
        this.gridOptions.context.chevKeyMap[cellRenderer.className] = column
      }
      cellRenderer.setAttribute('src', treeOpen)

      this.store.user.topic = params.data.name
      this.store.user.eventType = column
      if (!this.gridOptions.detailCellRendererParams)
        this.gridOptions.detailCellRendererParams = {}
      this.gridOptions.detailCellRendererParams.getDetailRowData =
        async params => {
          params.successCallback(
            await this.store.assort.fetchCompareMessages(
              params.data.name,
              column,
            ),
          )
        }

      if (params.node.detailNode)
        params.api.redrawRows({ rowNodes: [params.node.detailNode] })
    }

    this.detailCellRendererParams = params => {
      var res = this.gridOptions.detailCellRendererParams
      if (!res) res = {}
      res.detailGridOptions = {
        colWidth: '100%',
        rowHeight: 50,
        rowSelection: 'multiple',
        suppressRowClickSelection: true,
        enableRangeSelection: true,
        pagination: true,
        paginationPageSize: 25,
        columnDefs: [
          {
            headerName: 'Id',
            field: 'viewKey',
            minWidth: 150,
            maxWidth: 220,
            tooltipField: 'viewKey',
            cellStyle: {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          },
          {
            headerName: 'Changes',
            field: 'changes',
            minWidth: 250,
            cellStyle: {
              whiteSpace: 'normal',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: 'block',
              maxHeight: '3.6em',
              lineHeight: '1.8em',
            },
            cellRenderer: params => {
              if (!params.value) return ''
              const decodedValue = decodeURIComponent(
                JSON.parse('"' + params.value.replace(/\"/g, '\\"') + '"'),
              )
              return `
              <div style="
                overflow-y: auto;
                max-height: 3.6em;
                white-space: pre-wrap;
                word-wrap: break-word;
                padding: 2px;
              ">${decodedValue}</div>`
            },
          },
          {
            headerName: 'Last Retry Status',
            field: 'lastReplayStatus',
            minWidth: 180,
            maxWidth: 300,
          },
          {
            headerName: 'Replay',
            cellRenderer: this.replayButton.bind(this, ''),
            minWidth: 150,
            maxWidth: 220,
          },
        ],
        defaultColDef: {
          sortable: false,
          flex: 1,
          wrapText: true,
          resizable: true,
          tooltipComponentParams: {
            showDelay: 100,
            hideDelay: 200,
          },
        },
      }
      return res
    }
  },
  methods: {
    onGridReady(params) {
      setTimeout(() => {
        // params.api.doLayout()
        // params.api.redrawRows()
        params.api.refreshCells()
      }, 100)
    },
    appname() {
      return this.store.user.appname
    },
    replayButton(column, event) {
      var number = event.data[column.toLowerCase()]
      var topic = event.data.name
      var id = 'all'
      if (!column) {
        column = this.store.user.eventType
        number = '1'
        topic = this.store.user.topic
        id = event.data.id
      }
      var link = document.createElement('a')
      link.innerHTML = ' <div class="boxType">Replay ' + column + '</div>'
      link.addEventListener('click', async () => {
        this.resendData = {
          app: this.appname,
          column,
          topic,
          number,
          id,
        }
        this.$refs.resend.open(event, 'global')
      })
      return link
    },
  },
}
</script>
<style lang="scss">
.ag-body-viewport .ag-center-cols-container,
.ag-theme-fresh .ag-body-viewpor,
.ag-center-cols-viewport,
.ag-center-cols-clipper,
.ag-center-cols-clipper,
.ag-theme-fresh .ag-row-even,
.ag-theme-fresh .ag-paging-panel,
.ag-header-viewport,
.ag-header-container,
.ag-header-cell-label,
.ag-header-row {
  background-color: #e5e5e5 !important;
}
.ag-header-cell-label .ag-header-cell-text {
  color: #050505;
  font-size: 14px;
  font-weight: 700;
}
.ag-theme-fresh .ag-root-wrapper {
  border: none;
}
.ag-theme-fresh .ag-header-cell {
  border-right: none;
}
.ag-theme-fresh .ag-header-cell::after,
.ag-theme-fresh .ag-header-group-cell::after {
  content: none;
}
.ag-row-position-absolute {
  position: relative;
  margin: 20px 0;
}
#page-size {
  width: 60px;
}
ul.nav {
  border-bottom: 2px solid #d8d8d8;
  margin-top: 20px;
  margin-bottom: 40px;
}
ul.nav li {
  display: inline-block;

  a.router-link-active {
    color: #c21a31;
    border-left: 0 !important ;
    margin-right: 20px;
    border-bottom: 2px solid;
  }
}
a {
  color: #d8d8d8;
}
img {
  float: left;
  margin: 0 5px;
}
.ag-paging-row-summary-panel {
  position: absolute;
  left: 0;
}
.ag-paging-page-summary-panel .ag-paging-button button {
  position: relative;
}
.pages {
  width: 100%;
  background-color: #e5e5e5;
}

.button-top {
  position: fixed;
  top: 22px;
  right: 35px;
}
.boxType {
  border: 2px solid rgb(1, 1, 51);
  padding-left: 6px;
  border-radius: 10px;
  margin-left: 15px;
  color: #050505;

  &:hover {
    background-color: black;
    color: white;
    cursor: pointer;
  }
}
.pr-44 {
  padding-right: 13rem;
}
.textHeight {
  height: 250px;
}
.autocomplete {
  background-color: white !important;
  .selected {
    &:hover {
      background-color: gray !important;
    }
  }
}
.ag-tooltip {
  background-color: #ffffff !important;
  color: #000000 !important;
  border: 1px solid #2c2b2b !important;
  padding: 5px;
  border-radius: 4px;
  font-size: 14px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}
</style>
